@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Work Sans", sans-serif;
}

.sf {
  font-family: "SF Pro Text", ui-sans-serif, system-ui, -apple-system;
}

svg {
  fill: currentColor;
}

td {
  vertical-align: top;
}

.readable-clamp {
  width: min(65ch, 100%);
}

.minilabel {
  @apply uppercase tracking-wide text-gray-700 font-light text-xs;
}

.btn .sf-symbol {
  position: relative;
  top: -1px;
  margin-left: 5px;
}

.sf-symbol svg {
  width: 18px;
  height: 18px;
  display: inline;
}

.sf-symbol svg .fill {
  fill: #333;
}

.btn .sf-symbol svg .fill {
  fill: white;
}

.pill {
  @apply bg-gray-800 bg-opacity-50 rounded-lg px-1 py-0 text-xs text-white leading-tight;
  border: 1px solid transparent;
  border-width: 0 1px 1px 0;
}

td > .pill {
  @apply inline-block; /* This is to enable overflow ellipsis on mobile */
}

/*

@screen md {
  td .pill {
    @apply inline; /* needs to be inline-block for overflow ellipsis on mobile,
                      but on desktop it takes too much vertical space unless it’s
                      inline //
  }
}

*/

.pill-big {
  @apply px-2 py-1;
}

.pill-blue {
  @apply bg-blue-800 shadow-sm;
}

.pill-red {
  @apply bg-planitor-red shadow-sm;
}

.pill-yellow {
  @apply bg-planitor-gold shadow-sm;
}

.pill-green {
  @apply bg-planitor-green shadow-sm;
}

.highlight-blue {
  @apply text-blue-600;
}

.highlight-red {
  @apply text-planitor-red;
}

.highlight-yellow {
  @apply text-planitor-gold;
}

.highlight-green {
  @apply text-planitor-green;
}

.modal .backdrop {
  height: 100vh;
}

@screen sm {
  .munis {
    grid-template-columns: repeat(2, 1fr);
  }
}

@screen md {
  .munis {
    grid-template-columns: repeat(3, 1fr);
  }
}

@screen lg {
  .munis {
    grid-template-columns: repeat(4, 1fr);
  }
}

nav .search svg .fill {
  fill: rgba(255, 255, 255, 0.6);
}

nav .search input {
}

nav .search input::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.entities svg {
  position: relative;
  top: -2px;
  margin-right: 6px;
}

form input.error {
  @apply border-red-700;
}

@screen sm {
  .results .address {
    flex-basis: 12em;
  }
}

#teaser header .logo svg {
  @apply w-full h-auto;
}

#teaser section li {
}

#teaser section li svg {
  height: 28px;
  width: 28px;
  display: inline;
}

#teaser section a svg {
  display: inline;
}

.entity-map,
.nearby-map {
  height: 240px;
}

@screen sm {
  .entity-map,
  .nearby-map {
    height: 360px;
  }
}

@screen lg {
  .entity-map,
  .nearby-map {
    height: 480px;
    @apply max-w-screen-lg m-auto;
  }
}

.tabs .selected {
  @apply text-planitor-blue border-planitor-blue;
}
